import React, {useState, useEffect} from 'react'
import {ThankYouHeader} from 'components/Blocks'

const ThankYouPage = ({location}) => {
  const [name, setName] = useState('')
  useEffect(() => {
    if (location.state) {
      setName(location.state.name)
    }
  }, [])
  const meta = {
    title:
      'Thank you for subscribing | The Official site for Annie the Musical',
  }
  return <ThankYouHeader name={name}/>
}

export default ThankYouPage
